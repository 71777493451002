<template>
  <div>
    <!--    <pre>{{ selectedUserData }}</pre>-->
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
    >

      <h3>Administrative Information</h3>
      <b-row>

        <b-col>
          <b-form-group
            label="User ID"
          >
            <b-form-input
              :value="selectedUserData.user_id"
              trim
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="User Type"
          >
            <b-form-input
              :value="selectedUserData.user_type"
              trim
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Company Type"
          >
            <b-form-input
              :value="selectedUserData.company_type"
              trim
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Company CUID"
          >
            <b-form-input
              :value="selectedUserData.cuid"
              trim
              readonly
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>

        <b-col>
          <b-form-group
            label="Submitted"
          >
            <b-form-input
              :value="transformDate(selectedUserData.submission_at, false, 'FF' )"
              trim
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Created"
          >
            <b-form-input
              :value="transformDate(selectedUserData.created_at, false, 'FF' )"
              trim
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group
            label="Status"
          >
            <h1>
              <b-badge
                size="sm"
                :class="[{'badge-success': selectedUserData.status === 4},{'badge-danger': selectedUserData.status === 5}, {'badge-warning': selectedUserData.status === 1 || selectedUserData.status === 2|| selectedUserData.status === 3}]"
              >
                {{ humanise(transposeStatus(selectedUserData.status)) }}
              </b-badge>
            </h1>

          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="pt-3">
        Submission Data
      </h3>
      <b-row>
        <b-col>
          <b-form-group
            label="Payment Category"
          >
            <h4>
              <template v-for="(item, index) in selectedPaymentCategory">
                <b-badge
                  :key="`badge-${index}`"
                  class="d-inline-block p-1"
                  style="margin-right: 0.5rem"
                  size="lg"
                >
                  {{ item }}
                </b-badge>

              </template>
            </h4>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bank Statement"
          >
            <template v-if="pdfSource.hasPdf">
              <b-img
                :key="`file-${index}`"
                :src="pdfIconImage"
                width="50"
                @click="openModalViewBankStatementHeader"
              />
            </template>
            <template v-else>
              <div class="p-1 border">
                No Bank Statement Header attached
              </div>
            </template>

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Files"
          >

            <template
              v-if="selectedUserData.files.length > 0"
            >
              <div
                class="p-1"
              >
                <template v-for="(item, index) in selectedUserData.files">
                  <template v-if="getFileExtension(item.filename) === 'pdf'">
                    <div
                      :key="`file-${index}`"
                      class="mr-1 border d-inline-block p-1 text-center"
                    >
                      <b-img
                        class="cursor-pointer mb-1"
                        :src="pdfIconImage"
                        width="50"
                      />
                      <p>{{ item.filename }}</p>

                      <b-button
                        size="sm"
                        block
                        variant="secondary"
                        @click="download(item.filename)"
                      >Download</b-button>
                    </div>

                  </template>

                </template>

              </div>
            </template>
            <template v-else>
              <div class="p-1 border">
                No files attached
              </div>
            </template>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <template v-for="(item, index) in selectedUserInfo">
          <b-col
            :key="`info-${index}`"
            cols="6"
          >
            <b-form-group
              :label="toSentenceCase(index)"
            >
              <b-form-input
                :value="item"
                trim
                readonly
              />
            </b-form-group>
          </b-col>
        </template>
      </b-row>
    </ValidationObserver>

    <b-modal
      ref="ViewBankStatementHeaderPDF"
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="dark"
      size="lg"
      hide-footer
      title="Bank Account Statement Header"
    >
      <vue-pdf-embed
        v-if="pdfSource.hasPdf"
        :page="1"
        :source="pdfSource.src"
      />
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { isEmpty } from 'lodash'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Config from '@/views/administration/banks/formConfig'
import BaseInput from '@/leanpay-components/BaseInput.vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {
    VuePdfEmbed,
    ValidationObserver,
    BaseInput,
  },
  mixins: [humaniseMixin],
  props: {
    selectedUserData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      pdfIconImage: require('@/assets/images/icons/filetype-icons/pdf.png'),
    }
  },
  computed: {
    pdfSource() {
      let pdfBase64
      if (this.selectedUserData) {
        pdfBase64 = JSON.parse(this.selectedUserData.bank_statement)
        pdfBase64 = pdfBase64.base64
      }

      if (pdfBase64) {
        const obj = {
          hasPdf: true,
          src: `data:application/pdf;base64,${pdfBase64}`,
        }
        return obj
      }
      return {
        hasPdf: false,
        src: '',
      }
    },
    bankStatementHeader() {
      const test = this.selectedUserData.bank_statement
      if (test) {
        return test.base64
      }

      return []
    },
    selectedUserInfo() {
      const test = this.selectedUserData.info

      return test
    },
    selectedPaymentCategory() {
      const test = this.selectedUserData.product_category_text
      return test
    },

  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Onboarding' },
      { title: 'Detail' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemConstants/getSystemConstants')
    await this.$store.dispatch('systemConstants/getOnboardSystemConstants')

    if (!this.selectedUserData) {
      alert('hi')
      await this.$router.replace({ name: 'administration-onboarding-datatable' })
    }
  },
  methods: {
    transposeStatus(id) {
      const formStatuses = this.$store.getters['systemConstants/getOnboardSystemConstantsByKey']('formStatus')

      if (typeof formStatuses === 'object' && formStatuses !== null) {
        return Object.keys(formStatuses).find(key => formStatuses[key] === id)
      }
      return typeof formStatuses
    },
    download(itemId) {
      // downloadOnboardingFile
      console.log('download', itemId)
      this.$store.dispatch('onboarding/downloadOnboardingFile', {
        file_name: itemId,
      })
    },
    getFileExtension(filename) {
      const extension = filename.split('.').pop()
      return extension
    },
    openModalViewBankStatementHeader() {
      this.$refs.ViewBankStatementHeaderPDF.show()
    },
    toSentenceCase: camelCase => {
      if (camelCase) {
        const result = camelCase.replace(/([A-Z])/g, ' $1')
        return result[0].toUpperCase() + result.substring(1).toLowerCase()
      }
      return ''
    },
  },
}
</script>
